import React from "react";
import demo from "../../assets/demo-reel.mp4";
import "./Home.css";
import Contact from "../Contact/Contact.js";
import { Link } from "react-router-dom";

function Home() {
  const blurb = `
  Hey! I'm Brendan Connell, an animator and aspiring voice actor! I'm currently in my final year at College for Creative Studies in Detroit. I have an enormous passion for Dungeons & Dragons, which gave rise to my love of storytelling and character acting. D&D has been my driving inspiration for the majority of my work, both personal and not. Fantasy worldbuilding is the mortar that holds together the bricks of my creativity!
  `

  return (
    <>
      <main className="home-wrapper" id="home">
        <section className="video-container">
          <video
            src={demo}
            title="Brendan Connell's animation demo reel"
            // frameBorder="none"
            loop
            controls
          />
        </section>
        <section className="about">
          <h2>About Me</h2>
          <p>
            {blurb}
          </p>
          <Link to="/artwork" target="__blank">
            <button className="cta">
              View My Artwork!
            </button>
          </Link>
        </section>
      </main>
      <Contact/>
    </>
  );
}

export default Home;
