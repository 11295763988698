import "./Artwork.css";
import Gallery from "../../components/Gallery/Gallery.js";

function Artwork() {
  return (
    <>
      <h1>Artwork</h1>
      <Gallery />
    </>
  );
}

export default Artwork;
