import React from "react";
// import { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

function Header() {
  let url = useLocation()

  return (
    <header>
      <div className="logo">
        <h1>Brendan Connell's Portfolio</h1>
      </div>
      <nav>
        {url.pathname === "/" ? (
          <>
            <HashLink smooth to="#top">
              Home
            </HashLink>
            <HashLink smooth to="#contact">
              Contact
            </HashLink>
          </>
        ) : (
          <>
            <Link to="/">Home</Link>
            <HashLink to="/#contact">Contact</HashLink>
          </>
        )}
        <Link to="/artwork">Artwork</Link>
        <Link to="/projects">Projects</Link>
      </nav>
    </header>
  );
}

export default Header;
