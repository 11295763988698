import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer";
import Home from "./sections/Home/Home.js";
import Artwork from "./sections/Artwork/Artwork.js"
import Projects from "./sections/Projects/Projects.js";

function App() {
  return (
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/artwork" element={<Artwork/>}/>
        <Route path="/projects" element={<Projects/>}/>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
