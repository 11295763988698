import React from "react";
import "./Projects.css";
import film1 from "../../assets/film1.mp4";
import film2 from "../../assets/film2.mp4";
import film1Thumb from "../../assets/fable-thumb.webp";
import film2Thumb from "../../assets/mcfinkle-thumb.png";
import { GiTank } from "react-icons/gi";
import { FaYoutube } from "react-icons/fa";

function Film({ year, nid, yid, film, name, thumb }) {
  return (
    <section className="film">
      <h2>{name}</h2>
      <div className="video">
        <video
          src={film}
          poster={thumb}
          controls
          title={`${name} made in ${year}`}
        />
      </div>
      <div className="icons">
        <a
          href={`https://www.newgrounds.com/portal/view/${nid}`}
          rel="noopener noreferrer"
          target="_blank"
          title="View on Newgrounds!"
        >
          {<GiTank />}
        </a>
        <a
          href={`https://www.youtube.com/watch?v=${yid}`}
          rel="noopener noreferrer"
          target="_blank"
          title="View on Youtube!"
        >
          {<FaYoutube />}
        </a>
      </div>
      {/* {film === film2 ? null : <hr className="section-hr" />} */}
    </section>
  );
}

function Projects() {
  return (
    <>
      <div className="heading">
        <h1>Projects</h1>
      </div>
      <main className="wrapper">
        <Film
          nid="907109"
          yid="_gtLkWwolII"
          film={film1}
          year="2023"
          name='"A Fable" 1st Place Film CCS 48hour animation challenge'
          thumb={film1Thumb}
        />
        <Film
          nid="956875"
          yid="_IHbyXcDmSw"
          film={film2}
          year="2024"
          name='"The Corduroy McFinkle 25th Anniversary Spectacular" 1st Place CCS 48hour Challenge'
          thumb={film2Thumb}
        />
      </main>
    </>
  );
}

export default Projects;
